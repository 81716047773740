<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      spinner-variant="primary"
    >
      <b-table-simple
        bordered
        responsive
      >
        <b-tr>
          <b-td>Name</b-td>
          <b-td>{{ mentor.full_name }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Phone</b-td>
          <b-td>{{ mentor.phone_number }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Email</b-td>
          <b-td>{{ mentor.email }}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Website</b-td>
          <b-td>
            <b-link
              :href="prependHttps(mentor.website)"
              target="_blank"
            >{{ mentor.website }}
            </b-link>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>LinkedIn Profile</b-td>
          <b-td>
            <b-link
              :href="prependHttps(mentor.linkedin_profile)"
              target="_blank"
            >{{ mentor.linkedin_profile }}
            </b-link>
          </b-td>
        </b-tr>
        <b-tr>
          <b-td>Facebook Profile</b-td>
          <b-td>
            <b-link
              :href="prependHttps(mentor.facebook_profile)"
              target="_blank"
            >{{ mentor.facebook_profile }}
            </b-link>
          </b-td>
        </b-tr>
      </b-table-simple>
    </b-overlay>
  </div>
</template>
<script>

import {
  BLink, BTableSimple, BTd, BTr,
} from 'bootstrap-vue'

import gql from 'graphql-tag'

export default {
  components: {
    BTableSimple,
    BTr,
    BTd,
    BLink,
  },
  data() {
    return {
      mentor: {},
    }
  },
  methods: {
    prependHttps(url) {
      if (!url) return ''
      if (url.startsWith('http')) return url
      return `https://${url}`
    },
  },
  apollo: {
    mentor: {
      query() {
        return gql`
           query about_mentor {
           users_customuser(where: {users_associationtables: {id: {_eq: ${this.$route.params.id} }}}) {
             full_name
             phone_number
             email
             website
             linkedin_profile
             facebook_profile
          }
        }`
      },
      update: data => data.users_customuser[0],
    },
  },
}
</script>
